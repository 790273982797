import { LordIconSource, LordIconTrigger } from "~/types/assets"
import IconLord from '~/components/icon/Lord.vue'
import type { List } from "~/types/custom"

/* Workaround: re-exporting as a separated tsx module because nuxt/i18n (v9.x.x +)
  goes in error when useI18n composable is invoked within a <script lang="tsx" setup> component. */
export const services = (t: (s: string) => string, isMobile: boolean): List => {
  const items = [
    {
      id: 1,
      title: t('home.delta.cards.0.title'),
      textContent: t('home.delta.cards.0.text-content'),
      iconComponent: markRaw(
        defineComponent({
          render: () => (
            <IconLord
              src={LordIconSource.webDesign}
              trigger={LordIconTrigger.loop}
              revert={true}
              size={65}
              delay={500}
              class="lord-icon"
              loading="lazy"
            />
          ),
        }),
      ),
    },
    {
      id: 2,
      title: t('home.delta.cards.1.title'),
      textContent: t('home.delta.cards.1.text-content'),
      iconComponent: markRaw(
        defineComponent({
          render: () => (
            <IconLord
              src={LordIconSource.webApp}
              trigger={LordIconTrigger.loop}
              revert={true}
              size={65}
              delay={500}
              class="lord-icon"
              loading="lazy"
            />
          ),
        }),
      ),
    },
    {
      id: 3,
      title: t('home.delta.cards.2.title'),
      textContent: t('home.delta.cards.2.text-content'),
      iconComponent: markRaw(
        defineComponent({
          render: () => (
            <IconLord
              src={LordIconSource.cms}
              trigger={LordIconTrigger.loop}
              revert={true}
              size={65}
              delay={500}
              class="lord-icon"
              loading="lazy"
            />
          ),
        }),
      ),
    },
    {
      id: 4,
      title: t('home.delta.cards.3.title'),
      textContent: t('home.delta.cards.3.text-content'),
      iconComponent: markRaw(
        defineComponent({
          render: () => (
            <IconLord
              src={LordIconSource.servers}
              trigger={LordIconTrigger.loop}
              revert={true}
              size={65}
              delay={500}
              class="lord-icon"
              loading="lazy"
            />
          ),
        }),
      ),
    },
    {
      id: 5,
      title: t('home.delta.cards.5.title'),
      textContent: t('home.delta.cards.5.text-content'),
      iconComponent: markRaw(
        defineComponent({
          render: () => (
            <IconLord
              src={LordIconSource.seo}
              trigger={LordIconTrigger.loop}
              revert={true}
              size={65}
              delay={500}
              class="lord-icon"
              loading="lazy"
            />
          ),
        }),
      ),
    },
    {
      id: 6,
      title: t('home.delta.cards.6.title'),
      textContent: t('home.delta.cards.6.text-content'),
      iconComponent: markRaw(
        defineComponent({
          render: () => (
            <IconLord
              src={LordIconSource.robot}
              trigger={LordIconTrigger.loop}
              revert={true}
              size={65}
              delay={500}
              class="lord-icon"
              loading="lazy"
            />
          ),
        }),
      ),
    },
    {
      id: 7,
      title: t('home.delta.cards.8.title'),
      textContent: t('home.delta.cards.8.text-content'),
      iconComponent: markRaw(
        defineComponent({
          render: () => (
            <IconLord
              src={LordIconSource.analytics}
              trigger={LordIconTrigger.loop}
              revert={true}
              size={65}
              delay={500}
              class="lord-icon"
              loading="lazy"
            />
          ),
        }),
      ),
    },
    {
      id: 8,
      title: t('home.delta.cards.9.title'),
      textContent: t('home.delta.cards.9.text-content'),
      iconComponent: markRaw(
        defineComponent({
          render: () => (
            <IconLord
              src={LordIconSource.treasure}
              trigger={LordIconTrigger.loop}
              revert={true}
              size={65}
              delay={500}
              class="lord-icon"
              loading="lazy"
            />
          ),
        }),
      ),
    },
  ]

  if (!isMobile) {
    items.push({
      id: 9,
      title: t('home.delta.cards.4.title'),
      textContent: t('home.delta.cards.4.text-content'),
      iconComponent: markRaw(
        defineComponent({
          render: () => (
            <IconLord
              src={LordIconSource.microscope}
              trigger={LordIconTrigger.loop}
              revert={true}
              size={65}
              delay={500}
              class="lord-icon"
              loading="lazy"
            />
          ),
        }),
      ),
    })
    items.push({
      id: 10,
      title: t('home.delta.cards.7.title'),
      textContent: t('home.delta.cards.7.text-content'),
      iconComponent: markRaw(
        defineComponent({
          render: () => (
            <IconLord
              src={LordIconSource.loadBalancer}
              trigger={LordIconTrigger.loop}
              revert={true}
              size={65}
              delay={500}
              class="lord-icon"
              loading="lazy"
            />
          ),
        }),
      ),
    })
  }

  return items
}
