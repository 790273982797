import { LordIconSource, LordIconTrigger, MDIIcon } from "~/types/assets";
import type { Timeline } from "~/types/custom";
import IconBoolean from '~/components/icon/Boolean.vue'
import IconVendure from '~/components/icon/Vendure.vue'
import IconLord from '~/components/icon/Lord.vue'
import IconDeesup from '~/components/icon/Deesup.vue'
import IconUdemy from '~/components/icon/Udemy.vue'
import IconYoutube from '~/components/icon/Youtube.vue'
import IconWAD from '~/components/icon/WAD.vue'
import IconRedHat from '~/components/icon/RedHat.vue'
import IconMinio from '~/components/icon/Minio.vue'
import IconMolteni from '~/components/icon/Molteni.vue'


/* Workaround: re-exporting as a separated tsx module because nuxt/i18n (v9.x.x +)
  goes in error when useI18n composable is invoked within a <script lang="tsx" setup> component. */
export const timeline = (t: (s: string) => string): Timeline => [
    {
      date: '2018-04-01',
      title: t('home.epsilon.timeline.events.cisco-stage.title'),
      description: t('home.epsilon.timeline.events.cisco-stage.description'),
      // @ts-expect-error type mismatch
      logo: () => <UnoIcon class={`${MDIIcon?.cisco} text-6xl text-secondary`} />,
      anchor: {
        to: useLocaleThemePath('/certs/cisco-stage'),
        title: t('home.epsilon.timeline.events.cisco-stage.anchor.title'),
        'aria-label': t('home.epsilon.timeline.events.cisco-stage.anchor.aria-label')
      }
    },
    {
      date: '2019-01-01',
      title: t('home.epsilon.timeline.events.cisco-ite.title'),
      description: t('home.epsilon.timeline.events.cisco-ite.description'),
      /* @ts-expect-error TSX typerr */
      logo: () => <UnoIcon class={`${MDIIcon?.cisco} text-6xl text-secondary`} />,
      anchor: {
        to: useLocaleThemePath('/certs/ite'),
        title: t('home.epsilon.timeline.events.cisco-ite.anchor.title'),
        'aria-label': t('home.epsilon.timeline.events.cisco-ite.anchor.aria-label')
      }
    },
    {
      date: '2019-05-01',
      title: t('home.epsilon.timeline.events.cisco-ccna.title'),
      description: t('home.epsilon.timeline.events.cisco-ccna.description'),
      /* @ts-expect-error TSX typerr */
      logo: () => <UnoIcon class={`${MDIIcon?.cisco} text-6xl text-secondary`} />,
      anchor: {
        to: useLocaleThemePath('/certs/ccna'),
        title: t('home.epsilon.timeline.events.cisco-ccna.anchor.title'),
        'aria-label': t('home.epsilon.timeline.events.cisco-ccna.anchor.aria-label')
      }
    },
    {
      date: '2019-06-17',
      title: t('home.epsilon.timeline.events.high-school.title'),
      description: t('home.epsilon.timeline.events.high-school.description'),
      logo: () => (
        <IconLord
          src={LordIconSource.school}
          trigger={LordIconTrigger?.loop}
          revert={false}
          delay={500}
          size={65}
          loading="lazy"
        />
      ),
      anchor: {
        href: 'https://www.einsteinvimercate.edu.it/home/',
        rel: 'nofollow',
        title: t('home.epsilon.timeline.events.high-school.anchor.title'),
        'aria-label': t('home.epsilon.timeline.events.high-school.anchor.aria-label'),
        target: '_blank'
      }
    },
    {
      date: '2021-08-01' as unknown as Date,
      title: t('home.epsilon.timeline.events.boolean-course.title'),
      description: t('home.epsilon.timeline.events.boolean-course.description'),
      logo: () => <IconBoolean className="w-12 h-12" lazy={true} />,
      anchor: {
        href: 'https://boolean.careers/',
        rel: 'nofollow',
        title: t('home.epsilon.timeline.events.boolean-course.anchor.title'),
        'aria-label': t('home.epsilon.timeline.events.boolean-course.anchor.aria-label'),
        target: '_blank'
      }
    },
    {
      date: '2022-02-01' as unknown as Date,
      title: t('home.epsilon.timeline.events.boolean-cert.title'),
      description: t('home.epsilon.timeline.events.boolean-cert.description'),
      logo: () => <IconBoolean className="w-12 h-12" lazy={true} />,
      anchor: {
        to: useLocaleThemePath('/certs/boolean'),
        title: t('home.epsilon.timeline.events.boolean-cert.anchor.title'),
        'aria-label': t('home.epsilon.timeline.events.boolean-cert.anchor.aria-label')
      }
    },
    {
      date: '2022-02-01' as unknown as Date,
      title: t('home.epsilon.timeline.events.deesup.title'),
      description: t('home.epsilon.timeline.events.deesup.description'),
      logo: () => <IconDeesup width="100" />,
      anchor: {
        href: 'https://www.deesup.com',
        rel: 'nofollow',
        title: t('home.epsilon.timeline.events.deesup.anchor.title'),
        'aria-label': t('home.epsilon.timeline.events.deesup.anchor.aria-label'),
        target: '_blank'
      }
    },
    {
      date: '2022-09-01' as unknown as Date,
      title: t('home.epsilon.timeline.events.deesup-release.title'),
      description: t('home.epsilon.timeline.events.deesup-release.description'),
      logo: () => <IconDeesup width="100" />
    },
    {
      date: '2023-01-01' as unknown as Date,
      title: t('home.epsilon.timeline.events.host.title'),
      description: t('home.epsilon.timeline.events.host.description'),
      logo: () => (
        <IconLord
          src={LordIconSource.loadBalancer}
          trigger={LordIconTrigger?.loop}
          revert={false}
          delay={500}
          size={65}
          loading="lazy"
        />
      ),
      anchor: {
        href: 'https://host.it/',
        rel: 'nofollow',
        title: t('home.epsilon.timeline.events.host.anchor.title'),
        'aria-label': t('home.epsilon.timeline.events.host.anchor.aria-label'),
        target: '_blank'
      }
    },
    {
      date: '2023-02-01' as unknown as Date,
      title: t('home.epsilon.timeline.events.storage.title'),
      description: t('home.epsilon.timeline.events.storage.description'),
      logo: () => (
        <IconLord
          src={LordIconSource.server}
          trigger={LordIconTrigger?.loop}
          revert={false}
          delay={500}
          size={65}
          loading="lazy"
        />
      ),
      anchor: {
        href: 'https://storage.rottigni.tech/',
        rel: 'nofollow',
        title: t('home.epsilon.timeline.events.storage.anchor.title'),
        'aria-label': t('home.epsilon.timeline.events.storage.anchor.aria-label'),
        target: '_blank'
      }
    },
    {
      date: '2023-02-15' as unknown as Date,
      title: t('home.epsilon.timeline.events.cv.title'),
      description: t('home.epsilon.timeline.events.cv.description'),
      logo: () => (
        <IconLord
          src={LordIconSource.cv}
          trigger={LordIconTrigger?.loop}
          revert={false}
          delay={500}
          size={65}
          loading="lazy"
        />
      ),
      anchor: {
        href: 'https://cv.rottigni.tech/',
        rel: 'nofollow',
        title: t('home.epsilon.timeline.events.cv.anchor.title'),
        'aria-label': t('home.epsilon.timeline.events.cv.anchor.aria-label'),
        target: '_blank'
      }
    },
    {
      date: '2023-03-01' as unknown as Date,
      title: t('home.epsilon.timeline.events.portfolio.title'),
      description: t('home.epsilon.timeline.events.portfolio.description'),
      logo: () => (
        <IconLord
          src={LordIconSource.webApp}
          trigger={LordIconTrigger?.loop}
          revert={false}
          delay={500}
          size={65}
          loading="lazy"
        />
      )
    },
    {
      date: '2023-04-15' as unknown as Date,
      title: t('home.epsilon.timeline.events.data-science-course.title'),
      description: t('home.epsilon.timeline.events.data-science-course.description'),
      logo: () => <IconYoutube width={75} />,
      anchor: {
        href: 'https://www.youtube.com/@PieroSavastano',
        title: t('home.epsilon.timeline.events.data-science-course.anchor.title'),
        'aria-label': t('home.epsilon.timeline.events.data-science-course.anchor.aria-label'),
        rel: 'nofollow',
        target: '_blank'
      }
    },
    {
      date: '2023-06-06' as unknown as Date,
      title: t('home.epsilon.timeline.events.machine-learning-bootcamp.title'),
      description: t('home.epsilon.timeline.events.machine-learning-bootcamp.description'),
      logo: () => <IconUdemy width={150} />,
      anchor: {
        href: useLocaleThemePath('/certs/python-for-data-science-and-machine-learning-bootcamp'),
        title: t('home.epsilon.timeline.events.machine-learning-bootcamp.anchor.title'),
        'aria-label': t('home.epsilon.timeline.events.machine-learning-bootcamp.anchor.aria-label'),
        target: '_blank'
      }
    },
    {
      date: '2023-06-15' as unknown as Date,
      title: t('home.epsilon.timeline.events.raas.title'),
      description: t('home.epsilon.timeline.events.raas.description'),
      logo: () => <IconDeesup width="100" />
    },
    {
      date: '2023-06-29' as unknown as Date,
      title: t('home.epsilon.timeline.events.rottigni-tech.title'),
      description: t('home.epsilon.timeline.events.rottigni-tech.description'),
      /* @ts-expect-error TSX typerr */
      logo: () => <UnoIcon class={`${MDIIcon?.svelte} text-6xl text-secondary`} />,
      anchor: {
        href: 'https://www.rottigni.tech',
        title: t('home.epsilon.timeline.events.rottigni-tech.anchor.title'),
        'aria-label': t('home.epsilon.timeline.events.rottigni-tech.anchor.aria-label'),
        target: '_blank'
      }
    },
    {
      date: '2023-07-06' as unknown as Date,
      title: t('home.epsilon.timeline.events.inmemory-release.title'),
      description: t('home.epsilon.timeline.events.inmemory-release.description'),
      logo: () => <IconDeesup width="100" />
    },
    {
      date: '2023-07-24' as unknown as Date,
      title: t('home.epsilon.timeline.events.jupyterhub.title'),
      description: t('home.epsilon.timeline.events.jupyterhub.description'),
      /* @ts-expect-error TSX typerr */
      logo: () => <UnoIcon class={`${MDIIcon?.jupyter} text-6xl text-secondary`} />,
      anchor: {
        href: 'https://jupyterhub.rottigni.tech',
        title: t('home.epsilon.timeline.events.jupyterhub.anchor.title'),
        'aria-label': t('home.epsilon.timeline.events.jupyterhub.anchor.aria-label'),
        target: '_blank'
      }
    },
    {
      date: '2023-08-10' as unknown as Date,
      title: t('home.epsilon.timeline.events.smtp.title'),
      description: t('home.epsilon.timeline.events.smtp.description'),
      logo: () => (
        /* @ts-expect-error TSX typerr */
        <UnoIcon class={`${MDIIcon?.fastify} text-6xl text-secondary`} />
      ),
      anchor: {
        href: 'https://smtp.rottigni.tech',
        title: t('home.epsilon.timeline.events.smtp.anchor.title'),
        'aria-label': t('home.epsilon.timeline.events.smtp.anchor.aria-label'),
        target: '_blank'
      }
    },
    {
      date: '2023-09-09',
      title: t('home.epsilon.timeline.events.vendure-scalapay.title'),
      description: t('home.epsilon.timeline.events.vendure-scalapay.description'),
      logo: () => <IconVendure className="w-12 h-12" lazy={true} />,
      anchor: {
        href: 'https://www.npmjs.com/package/vendure-scalapay',
        title: t('home.epsilon.timeline.events.vendure-scalapay.anchor.title'),
        'aria-label': t('home.epsilon.timeline.events.vendure-scalapay.anchor.aria-label'),
        target: '_blank'
      }
    },
    {
      date: '2023-09-15',
      title: t('home.epsilon.timeline.events.nlp.title'),
      description: t('home.epsilon.timeline.events.nlp.description'),
      logo: () => <IconUdemy width={150} />,
      anchor: {
        href: useLocaleThemePath('/certs/python-for-natural-language-processing'),
        title: t('home.epsilon.timeline.events.nlp.anchor.title'),
        'aria-label': t('home.epsilon.timeline.events.nlp.anchor.aria-label'),
        target: '_blank'
      }
    },
    {
      date: '2023-09-22',
      title: t('home.epsilon.timeline.events.sentry.title'),
      description: t('home.epsilon.timeline.events.sentry.description'),
  
      logo: () => (
        /* @ts-expect-error TSX typerr */
        <UnoIcon class={`${MDIIcon?.grafana} text-6xl text-secondary`} />
      ),
      anchor: {
        href: 'https://sentry.rottigni.tech',
        title: t('home.epsilon.timeline.events.sentry.anchor.title'),
        'aria-label': t('home.epsilon.timeline.events.sentry.anchor.aria-label'),
        target: '_blank'
      }
    },
    {
      date: '2023-11-16',
      title: t('home.epsilon.timeline.events.k8s.title'),
      description: t('home.epsilon.timeline.events.k8s.description'),
      logo: () => <IconUdemy width={150} />,
      anchor: {
        href: useLocaleThemePath('/certs/k8s-mastery'),
        title: t('home.epsilon.timeline.events.k8s.anchor.title'),
        'aria-label': t('home.epsilon.timeline.events.k8s.anchor.aria-label'),
        target: '_blank'
      }
    },
    {
      date: '2024-02-02',
      title: t('home.epsilon.timeline.events.k8s-migration.title'),
      description: t('home.epsilon.timeline.events.k8s-migration.description'),
      logo: () => (
        /* @ts-expect-error TSX typerr */
        <UnoIcon class={`${MDIIcon?.k8s} text-6xl text-secondary`} />
      ),
      anchor: {
        href: 'https://www.linkedin.com/pulse/helm-bare-metal-kubernetes-cluster-lorenzo-rottigni-cssff/',
        title: t('home.epsilon.timeline.events.k8s-migration.anchor.title'),
        'aria-label': t('home.epsilon.timeline.events.k8s-migration.anchor.aria-label'),
        target: '_blank'
      }
    },
    {
      date: '2023-05-08',
      title: t('home.epsilon.timeline.events.computer-vision.title'),
      description: t('home.epsilon.timeline.events.computer-vision.description'),
      logo: () => <IconUdemy width={150} />,
      anchor: {
        href: useLocaleThemePath('/certs/python-for-computer-vision'),
        title: t('home.epsilon.timeline.events.computer-vision.anchor.title'),
        'aria-label': t('home.epsilon.timeline.events.computer-vision.anchor.aria-label'),
        target: '_blank'
      }
    },
    {
      date: '2024-06-21',
      title: t('home.epsilon.timeline.events.vendure-article.title'),
      description: t('home.epsilon.timeline.events.vendure-article.description'),
      logo: () => <IconVendure />,
      anchor: {
        href: 'https://www.linkedin.com/pulse/vendure-backbone-deesups-growth-deesup-vckvf',
        title: t('home.epsilon.timeline.events.vendure-article.anchor.title'),
        'aria-label': t('home.epsilon.timeline.events.vendure-article.anchor.aria-label'),
        target: '_blank'
      }
    },
    {
      date: '2024-06-27',
      title: t('home.epsilon.timeline.events.google-cloud-summit.title'),
      description: t('home.epsilon.timeline.events.google-cloud-summit.description'),
      logo: () => (
        /* @ts-expect-error TSX typerr */
        <UnoIcon class={`${MDIIcon?.google} text-6xl text-secondary`} />
      ),
      anchor: {
        href: 'https://cloudonair.withgoogle.com/events/summit-milan-24',
        title: t('home.epsilon.timeline.events.google-cloud-summit.anchor.title'),
        'aria-label': t('home.epsilon.timeline.events.google-cloud-summit.anchor.aria-label'),
        target: '_blank'
      }
    },
    {
      date: '2024-07-15',
      title: t('home.epsilon.timeline.events.k3s.title'),
      description: t('home.epsilon.timeline.events.k3s.description'),
      logo: () => (
        /* @ts-expect-error TSX typerr */
        <UnoIcon class={`${MDIIcon?.k3s} text-6xl text-secondary`} />
      ),
      anchor: {
        href: 'https://k3s.io/',
        title: t('home.epsilon.timeline.events.k3s.anchor.title'),
        'aria-label': t('home.epsilon.timeline.events.k3s.anchor.aria-label'),
        target: '_blank'
      }
    },
    {
      date: '2024-07-(16-18)',
      title: t('home.epsilon.timeline.events.wad.title'),
      description: t('home.epsilon.timeline.events.wad.description'),
      logo: () => <IconWAD width={100} class="mb-2" />,
      anchor: {
        href: 'https://www.wearedevelopers.com/',
        title: t('home.epsilon.timeline.events.wad.anchor.title'),
        'aria-label': t('home.epsilon.timeline.events.wad.anchor.aria-label'),
        target: '_blank'
      }
    },
    {
      date: '2024-11-19',
      title: t('home.epsilon.timeline.events.redhat-summit.title'),
      description: t('home.epsilon.timeline.events.redhat-summit.description'),
      logo: () => <IconRedHat width={100} class="mb-2" />,
      anchor: {
        href: 'https://www.redhat.com/',
        title: t('home.epsilon.timeline.events.redhat-summit.anchor.title'),
        'aria-label': t('home.epsilon.timeline.events.redhat-summit.anchor.aria-label'),
        target: '_blank'
      }
    },
    {
      date: '2025-02-10',
      title: t('home.epsilon.timeline.events.s3.title'),
      description: t('home.epsilon.timeline.events.s3.description'),
      logo: () => <IconMinio height={100} width={100} class="mb-2" />,
      anchor: {
        href: 'https://minio.rottigni.tech',
        title: t('home.epsilon.timeline.events.s3.anchor.title'),
        'aria-label': t('home.epsilon.timeline.events.s3.anchor.aria-label'),
        target: '_blank'
      }
    },
    {
      date: '2025-02-15',
      title: t('home.epsilon.timeline.events.molteni.title'),
      description: t('home.epsilon.timeline.events.molteni.description'),
      logo: () => <IconMolteni width={150} class="mb-2" />,
      anchor: {
        href: 'https://molteni.deesup.com',
        title: t('home.epsilon.timeline.events.molteni.anchor.title'),
        'aria-label': t('home.epsilon.timeline.events.molteni.anchor.aria-label'),
        target: '_blank'
      }
    }
  ]