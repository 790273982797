<script lang="ts" setup>
import { useRoot } from '~/store';

const props = defineProps<{
  color?: string
}>()

const { theme } = storeToRefs(useRoot())
</script>

<template>
  <svg viewBox="0 0 425.2 73.5">
    <g>
      <path
        d="M65.6,68.8h9.3v2.6H44.2v-2.6h9.3V6h-0.3L34.5,71.4h-3.2L12.5,6h-0.2v62.9h9.1v2.6H0v-2.6h9.3V4.2H0V1.6h24.3l13.2,47.8L51.1,1.6h23.8v2.6h-9.3V68.8z"
        :fill="color || theme.hexSecondary"
      />
      <path
        d="M94.6,29.4c10.6,0,19.8,10.4,19.8,22.1c0,11.7-9.1,21.5-19.8,21.5c-10.6,0-19.8-9.8-19.8-21.5C74.8,39.7,83.9,29.4,94.6,29.4z M94.6,31.5c-9.2,0-8.6,8.2-8.6,20c0,13.6,0.2,19.5,8.6,19.5s8.6-5.9,8.6-19.5C103.1,39.6,103.8,31.5,94.6,31.5z"
        :fill="color || theme.hexSecondary"
      />
      <path d="M114.4,71.4v-2.6h7.1V4.2h-6.8V1.6c5.7,0.2,11.4-0.4,16.9-1.6v68.8h6.9v2.6H114.4z" :fill="color || theme.hexSecondary" />
      <path
        d="M144.3,19.1c3.4,0,6.8-0.4,10.2-0.6v12.8h8.9v3.1h-8.9v28.8c0,3.3,0.1,5.8,3.7,5.8c3,0,4.8-2.6,5.7-5.5l1.9,1c-2,5.9-6,8.6-11.5,8.6c-8.6,0-10-3.2-10-9V34.4h-6v-3.1h6V19.1z"
        :fill="color || theme.hexSecondary"
      />
      <path
        d="M177.6,48.3v8.4c0,7.3,0.4,14.2,8.4,14.2c6.8,0,12-5.4,13.8-12.5h2.4c-2.4,10.1-8.9,14.6-18.1,14.6c-10.3,0-17.7-11-17.7-21.6c0-11.5,8.5-22,18.7-22c8.8,0,16.8,6.7,16.8,17c0,2.2-0.8,1.9-2.4,1.9H177.6z M191.3,45.7c0-7,1.6-14.2-6.8-14.2c-7.8,0-7,7.6-7,14.2H191.3z"
        :fill="color || theme.hexSecondary"
      />
      <path
        d="M220.4,39.4h0.2c3-6.5,7.2-10,14-10c12.5,0,11.5,9.7,11.5,20.1v19.3h5.9v2.6h-21.8v-2.6h5.9V50c0-9.7,0.9-16.8-5.4-16.8c-4,0-7.7,4.8-9.1,8.7c-1.2,3.5-1.2,8.3-1.2,13.2v13.8h5.9v2.6H204v-2.6h6.4V32.6H204V30c4.8,0.2,10.5-0.2,16.4-0.4V39.4z"
        :fill="color || theme.hexSecondary"
      />
      <path
        d="M254.8,71.4v-2.6h6.9V32.6h-6.9V30c5.6,0.2,11.3-0.2,17-0.4v39.2h7v2.6H254.8z M266,16.6c-2.9,0-5.1-3.2-5.1-6.3c0-2.8,2.5-5.6,5.1-5.6c2.7,0,5.6,2.5,5.6,5.6C271.6,13.9,269.2,16.6,266,16.6z"
        :fill="color || theme.hexSecondary"
      />
      <path
        d="M332.5,65.3c-5.8,5.2-13.4,8.3-21,8.3c-10.5,0-20.1-6.5-20.1-19.2c0-13.2,10.9-18.5,20.1-22.8c-2.4-4.3-5.3-10.4-5.3-15.4c0-10.5,8-15.6,16.6-15.6c7,0,16.3,2.9,16.3,12.4c0,8.5-9.3,13.3-15.3,15.8l15.5,24.4c4.6-6,7.3-14.3,7.2-22.3h-9.2v-2.6h21v2.6h-8.9c0,8.1-3.3,18.8-8.5,24.8l8.1,13.2h9.3v2.6h-21.8L332.5,65.3z M322.1,26.5c5.8-1.8,8.2-6.3,8.2-12.8c0-6.3-2-10.8-8.1-10.8c-4.3,0-7.6,2.8-7.6,7.8c0,2.6,1.1,5.6,2.8,8.3L322.1,26.5z M312.7,33.6c-5.9,2.9-10.4,5.3-10.4,13.4c0,4.8,2.8,11.1,5.3,15c2.6,4.1,5.4,7.1,10.2,7.1c5,0,9.4-2.7,13.3-6.2L312.7,33.6z"
        :fill="color || theme.hexSecondary"
      />
      <path
        d="M425.2,50.6v21.4h-2.3l-4.3-5c-4.7,4.1-11.7,6-17.9,6c-18.4,0-32.3-18.7-32.3-36.1c0-13.6,7.2-26.5,18.9-33.5C391.5,1,396.7,0,401.6,0c5.9,0,11.6,1.7,16.2,5.4L422,1h2.3v20.2h-3c-1.6-11.4-9.5-18.6-19.8-18.6c-6.7,0-10.1,1.5-13.8,7.1c-4.9,7.5-3.9,19.7-3.9,28.1c0,6-0.2,14.1,1.8,20.9c2,6.7,6.9,11.2,14.9,11.6c12.9,0.6,18.8-8.5,21.9-19.8H425.2z"
        :fill="color || theme.hexSecondary"
      />  
    </g>
  </svg>
</template>
